import { Box, calc, Flex } from "@chakra-ui/react";
import React from "react";
import { BodyText } from "../../texts/BodyText";
import { Hero } from "../../texts/Hero";
import { HandPointingAtNetwork } from "../../visuals/HandPointingAtNetwork";

export function WeAreIndevit() {
  return (
    <Flex
      as="section"
      display="flex"
      w="100vw"
      flex="1 1 auto"
      flexDirection={{
        base: "column-reverse",
        lg: "row",
      }}
      alignItems={{
        base: "normal",
        lg: "center",
      }}
      justifyContent={{
        base: "flex-end",
        lg: "center",
      }}
    >
      <Box
        pt={{
          base: "20",
          lg: 0,
        }}
        pb={{
          base: "12",
          lg: 0,
        }}
        pl={{
          base: 2,
          lg: "calc(calc(100% - 900px) / 2)",
          xl: "calc(calc(100% - 1024px) / 2)",
          "2xl": "calc(calc(100% - 1280px) / 2)",
        }}
        pr={{
          base: 2,
          lg: 8,
        }}
        mt={{
          lg: "-50px",
        }}
        flex={{
          base: "0 0 auto",
          lg: "1 1 25%",
        }}
      >
        <Hero
          mb="6"
          textAlign={{
            base: "center",
            lg: "left",
          }}
        >
          We are Indevit
        </Hero>
        <BodyText
          whiteSpace={{
            lg: "nowrap",
          }}
          textAlign={{
            base: "center",
            lg: "left",
          }}
        >
          Experts at translating business into code.
          <br />
          Bridge builders between code and users.
          <br />
          Passionate problem solvers.
          <br />
        </BodyText>
      </Box>
      <HandPointingAtNetwork
        flex={{
          base: "0 0 auto",
          lg: "1 1 45%",
          "2xl": "1 1 30%",
        }}
        pt={{
          base: "10",
        }}
        ml={{
          base: "14%",
          lg: "0",
        }}
      />
    </Flex>
  );
}
