import { Box, Grid, GridItem, Heading } from "@chakra-ui/react";
import { Section } from "../../layout/Section";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import GridItemVisual from "../../layout/GridItemVisual";
import { heading } from "../../../constants/fonts";

interface TrustedByFunnelProps {}

const TrustedByFunnel: React.FC<TrustedByFunnelProps> = () => {
  return (
    <Section>
      <Box display="flex" flexDirection="column" gap={14}>
        <Heading
          fontSize="56px"
          fontWeight="medium"
          fontFamily={heading}
          textAlign="center"
        >
          Trusted by
        </Heading>
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
          templateRows="repeat(2, 1fr)"
          gap={{ base: 4, lg: "4rem" }}
          flexShrink={1}
        >
          <GridItemVisual
            image={
              <StaticImage
                alt="UI/UX design"
                src="../../../images/logo-mirka.jpg"
                placeholder="none"
              />
            }
            bg="white"
          />
          <GridItemVisual
            image={
              <StaticImage
                alt="UI/UX design"
                src="../../../images/logo-john-nurminen.jpg"
                placeholder="none"
              />
            }
            bg="white"
          />
          <GridItemVisual
            image={
              <StaticImage
                alt="UI/UX design"
                src="../../../images/logo-ntc.jpg"
                placeholder="none"
              />
            }
            bg="#414141"
          />
          <GridItemVisual
            image={
              <StaticImage
                alt="UI/UX design"
                src="../../../images/logo-jepo.jpg"
                placeholder="none"
              />
            }
            bg="#4BA22F"
          />
          <GridItemVisual
            image={
              <StaticImage
                alt="UI/UX design"
                src="../../../images/logo-wasaline.jpg"
                placeholder="none"
              />
            }
            bg="white"
          />
          <GridItemVisual
            image={
              <StaticImage
                alt="UI/UX design"
                src="../../../images/logo-entia.jpg"
                placeholder="none"
              />
            }
            bg="white"
          />
        </Grid>
      </Box>
      <Box display="flex"></Box>
    </Section>
  );
};

export default TrustedByFunnel;
