import { Box, BoxProps } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { secondary } from "../../constants/colors";
import { Circle } from "./Circle";
import { Network } from "./Network";


export function HandPointingAtNetwork(props: BoxProps) {
  return (
    <Box position="relative" sx={{ aspectRatio: "900 / 539" }} {...props}>
      <Circle
        width="60%"
        bg={secondary}
        position="absolute"
        left="11%"
      />
      <StaticImage
        src="../../images/handPointing.png"
        alt=""
        placeholder="none"
        style={{ position: "absolute", right: "0", width: "70%", marginTop: "8%" }}
      />
      <Network
        size="30%"
        position="absolute"
        left="0%"
        mt="17%"
      />
    </Box>
  )
}