import { Box, Heading, Stack } from "@chakra-ui/react";
import React, { useRef } from "react";
import { primary } from "../../../constants/colors";
import { Section } from "../../layout/Section";
import { BodyText } from "../../texts/BodyText";
import { Hero } from "../../texts/Hero";
import { HintingFlap } from "../../visuals/HintingFlap";
import { heading } from "../../../constants/fonts";
import { Button } from "../../inputs/Button";
import { Link } from "gatsby";

export function ServicesFunnel() {
  const section = useRef<HTMLDivElement>(null);

  const scroll = () => {
    section.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <Box
      ref={section}
      position="relative"
      bg="black"
      zIndex={1}
      overflow="visible"
    >
      <HintingFlap
        role="button"
        aria-label="Scroll to Services section"
        onClick={scroll}
        cursor="pointer"
        position="absolute"
        width={{
          base: "200px",
          lg: "300px",
        }}
        top={{
          base: "-50px",
          lg: "-75px",
        }}
        left="0"
        right="0"
        margin="auto"
      />
      <Section centerContent>
        <Stack alignItems="center" gap={12}>
          <Hero color="white">Our services</Hero>
          <Box
            display="flex"
            flexDirection={{ base: "column", lg: "row" }}
            width="100%"
            gap={12}
          >
            <Stack flexGrow={1} alignItems="center">
              <Heading
                textAlign="center"
                fontSize="1.5rem"
                color="white"
                fontFamily={heading}
                fontWeight="medium"
              >
                Business systems
              </Heading>
              <BodyText textAlign="center" color="white" pb="2rem" flexGrow={1}>
                As skilled ERP, CMMS, TMS, MES, and MOM experts, we offer our
                customers an all-round look at their IT challenges based on a
                firm understanding of their business needs.
              </BodyText>
              <Link to="/services/system">
                <Button colorScheme="primary">Here's how we do it</Button>
              </Link>
            </Stack>
            <Stack flexGrow={1} alignItems="center" gap={0}>
              <Heading
                textAlign="center"
                fontSize="1.5rem"
                color="white"
                fontFamily={heading}
                fontWeight="medium"
              >
                Visual lab
              </Heading>
              <BodyText textAlign="center" color="white" flexGrow={1} paddingBottom="2rem">
                We provide a range of services including content creation,
                prototyping, UI/UX design and design system knowledge,
                delivering seamless and user-centric digital experiences.
              </BodyText>
              <Box justifySelf="flex-end">
                <Link to="/services/visuallab">
                  <Button colorScheme="secondary">Explore our services</Button>
                </Link>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Section>
    </Box>
  );
}
