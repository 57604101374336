import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { secondary } from "../../constants/colors";

export interface HintingFlapProps extends BoxProps {
}

export function HintingFlap(props: HintingFlapProps) {

  return (
    <Box as="svg" {...props} viewBox="-20 -20 240 60" >
      <polygon fill="black" strokeLinejoin="round" strokeWidth="30px" stroke="black" points="104,0 96,0 0,50 200, 50">
      </polygon>
      <polygon fill={secondary} strokeLinejoin="round" strokeWidth="2px" stroke={secondary} points="100,25 85,10 115, 10"></polygon>
    </Box>
  )
}