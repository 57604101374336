import * as React from "react";
import { Layout } from "../components/layout/Layout";
import { AboutUsFunnel } from "../components/sections/home/AboutUsFunnel";
import { CareersFunnel } from "../components/sections/home/CareersFunnel";
import { ServicesFunnel } from "../components/sections/home/ServicesFunnel";
import { WeAreIndevit } from "../components/sections/home/WeAreIndevit";
import { MetaTags } from "../components/MetaTags";
import TrustedByFunnel from "../components/sections/home/TrustedByFunnel";

function IndexPage() {
  return (
    <Layout heroSection={<WeAreIndevit />}>
      <ServicesFunnel />
      <AboutUsFunnel />
      <CareersFunnel />
      <TrustedByFunnel />
    </Layout>
  );
}

export default IndexPage;

export const Head = () => <MetaTags type="website" />;
