import { Box } from "@chakra-ui/react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Button } from "../../inputs/Button";
import { Section } from "../../layout/Section";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Hero } from "../../texts/Hero";
import { CircularImage } from "../../visuals/CircularImage";

export function CareersFunnel() {
  return (
    <Box bg="white">
      <Section>
        <TextAndVisual
          textPart={
            <>
              <Hero>
                Is problem solving your IT passion too?
              </Hero>
              <BodyText mt="4" mb="10">
                Our company is growing quickly.
                We are looking for talented coders and IT experts,
                who share our genuine interest in learning new things
                and understanding our customers' businesses.
              </BodyText>
              <Link to="/careers">
                <Button
                  as="span"
                  shape="round"
                  colorScheme="black">
                  See our open positions
                </Button>
              </Link>
            </>}
          visualPart={
            <CircularImage
              adornment="bottom"
              marginX={{
                base: "30px",
                lg: 0
              }}>
              <StaticImage
                src="../../../images/indevit_working.jpg"
                alt="Indevit employees in a meeting"
                placeholder="blurred" />
            </CircularImage>}
        />
      </Section>

    </Box>
  )
}