import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Button } from "../../inputs/Button";
import { Section } from "../../layout/Section";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Hero } from "../../texts/Hero";

export function AboutUsFunnel() {
  return (
    <Section>
      <TextAndVisual
        isVisualLeft
        visualPart={
          <StaticImage
            src="../../../images/indevit_sofa.jpg"
            alt="Indevit employees sitting in a sofa"
            placeholder="blurred"
          />
        }
        textPart={
          <>
            <Hero>The bridge between business and code</Hero>
            <BodyText mt="4" mb="10">
              We like to see ourselves as bridge builders. Because our work's
              pride and joy comes from bridging the gap between business needs
              and high-quality software.
            </BodyText>
            <Link to="/about">
              <Button as="span" shape="round" colorScheme="secondary">
                Get to know us
              </Button>
            </Link>
          </>
        }
      />
    </Section>
  );
}
